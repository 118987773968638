@font-face {
    font-family: 'Logo';
    src: url('./fonts/Collection-Style2.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
  }

@font-face {
    font-family: 'IIIncrementsSans-Bold';
    src: url('./fonts/IIIncrementsSans-Bold.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'IIIncrementsSans';
    src: url('./fonts/IIIncrementsSans.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }
  
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  html {
    font-size: 16px;
  }
  
  body {
    font-family: 'IIIncrementsSans', sans-serif;
    overflow-x: hidden;
  }
  
  .subheader {
    color: white;
    font-size: 2rem;
    font-weight: bold;
    font-family: 'IIIncrementsSans-Bold', Arial, sans-serif;
  }
  
  .vertical-divs {
    display: flex;
    flex-direction: column;
    gap: 4vw; /* Dynamic gap based on viewport width */
    margin-top: 4vh; /* Dynamic margin-top based on viewport height */
  }
  
  .rounded-div {
    background-color: #fff;
    padding: 1rem;
    border-radius: 20px;
    text-align: center;
    font-size: 1rem;
    font-family: 'IIIncrementsSans-Bold', Arial, sans-serif;
  }
  
  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100vh - 2rem); /* Full viewport height minus padding */
    position: relative;
    z-index: 1;
    transform: translateY(-10%); /* Adjust to move the image slightly upward */
  }
  
  .home-image {
    width: 100%;
    max-width: 100%;
    height: auto;
    object-fit: cover;
    max-height: calc(100vh - 2rem); /* Adjust to leave some space at the bottom */
    max-width: 100vw; /* Limits the width to the original resolution or viewport width */
  }
  
  .bottom-half {
    background-color: #000;
    height: 35vh;
    width: 100%;
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 3rem;
  }
  