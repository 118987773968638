.gallery-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1.25rem; /* Adds spacing below the nav bar */
  }
  
  .header-title {
    font-family: 'IIIncrementsSans-Bold', sans-serif;
    font-size: 1.25rem;
    text-align: left;
    margin-left: 2rem;
    letter-spacing: 0.25rem; /* Adjust the value to increase or decrease spacing */
  }
  
  .custom-dropdown {
    position: relative;
    display: inline-block;
    margin-right: 2rem;
  }
  
  .dropdown-header {
    font-family: 'IIIncrementsSans', sans-serif;
    font-size: 1rem;
    cursor: pointer;
    padding: 10px;
    background-color: rgba(50, 50, 50, 0.8); /* Grey with slight transparency */
    border: 0.15rem solid black;
    text-align: center;
    color: white; /* Text color white */
    width: 100%; /* Ensures it fills the width of the parent */
  }
  
  .dropdown-list {
    display: none;
    position: absolute;
    background-color: rgba(50, 50, 50, 0.8); /* Grey with slight transparency */
    border: 0.15rem solid black;
    border-top: none; /* Remove the border on the top */
    padding: 0;
    margin: 0;
    list-style: none;
    width: 100%; /* Matches the width of the dropdown header */
    z-index: 1000;
  }
  
  .custom-dropdown:hover .dropdown-list {
    display: block;
  }
  
  .dropdown-list li {
    font-family: 'IIIncrementsSans', sans-serif;
    font-size: 1rem;
    padding: 10px;
    text-align: center;
    color: white; /* Text color white */
    width: 100%; /* Ensure consistent width for all list items */
  }
  
  .dropdown-list li:hover {
    background-color: rgba(0, 0, 0, 0.1); /* Slight hover effect */
    cursor: pointer;
  }
  
  .black-box {
    background-color: black;
    min-height: calc(100vh - 5rem); /* Adjust height as needed */
    margin-top: 0.5rem; /* Reduced space between the subheading and the gallery */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    box-sizing: border-box;
  }
  
  .image-gallery {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 2 images per row */
    grid-gap: 1rem; /* Spacing between images */
    justify-items: center; /* Center images within the grid */
  }
  
  .gallery-image {
    width: 100%; /* Image takes full width of its grid cell */
    height: auto;
    object-fit: cover;
  }
  