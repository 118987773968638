.app-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2em;
  position: sticky;
  top: 0;
  background-color: white; /* Optional: to give the header a background */
  z-index: 10; /* Ensures the header stays on top of other elements */
}

.logo {
  font-size: 1.25rem;
  font-family: 'IIIncrementsSans-Bold', Arial, sans-serif;
}

.nav-bar ul {
  list-style: none;
  display: flex;
  gap: 1em;
}

.nav-bar a {
  text-decoration: none;
  color: #000;
  font-size: 1rem;
}

.header-line {
  width: calc(100% - 2em);
  height: 1px;
  background-color: #000;
  margin: 0 auto;
}
